<template>
    <div class="TableList">
        <div class="ManagementTable draggable">
            <el-table
                :key="tableKey"
                :data="tableData"
                :stripe="stripe"
                ref="table"
                size="small"
                :header-cell-style="headerCellStyle"               
                :show-summary="showSummary"
                v-loading="loading"
                row-key="id"
                :row-class-name="rowClassName"
            >
                <el-table-column v-if="selection" type="selection" width="60"> </el-table-column>
                <template v-for="i in colrs">
                    <el-table-column
                        :key="i.name"
                        :label="i.label"
                        :column-key="i.prop"
                        :width="i.width"
                        :fixed="i.fixed"
                        :sortable="i.sortable"
                    >
                        <template slot-scope="scope">
                            <slot name="TableItem" :row="scope.row" :colr="i" :rowIndex="scope.$index">
                                <span >{{ scope.row[i.prop] }}</span>
                            </slot>
                        </template>
                        <template slot="header">
                            <slot name="header" :row="i">
                                <template v-if="TableFilters && TableFilters[i.prop]">
                                    <div
                                        class="filterHeader"
                                        @click="
                                            filter(
                                                i.prop,
                                                TableFilters[i.prop],
                                                TableDefaultFilter ? TableDefaultFilter[i.prop] : []
                                            )
                                        "
                                    >
                                        {{ i.label }}
                                        <i
                                            class="listScreen"
                                            :class="{
                                                cur: TableDefaultFilter && TableDefaultFilter[i.prop].length > 0
                                            }"
                                        ></i>
                                    </div>
                                </template>
                                <template v-else>
                                    {{ i.label }}
                                </template>
                            </slot>
                        </template>
                    </el-table-column>
                </template>
                <el-table-column
                    label="操作"
                    :width="ButtonWidth"
                    v-if="this.$scopedSlots.Button && btnFixed"
                    :fixed="fixed"
                >
                    <template slot-scope="scope">
                        <slot name="Button" :index="scope.$index" :row="scope.row"></slot>
                    </template>
                </el-table-column>
                <el-table-column label="操作" :width="ButtonWidth" v-if="this.$scopedSlots.Button && btnFixed == false">
                    <template slot-scope="scope">
                        <slot name="Button" :index="scope.$index" :row="scope.row"></slot>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-pagination
            :hide-on-single-page="hideOnSinglePage"
            :current-page.sync="currentPage"
            :page-sizes="[20, 50, 100, 500]"
            layout="total, sizes, prev, pager, next"
            :page-size="pagesize"
            @size-change="handleSizeChange"
            @current-change="currentChange"
            :total="total"
        >
        </el-pagination>
        <div class="addLine" v-if="isShowAddLine" @click="addLine">
            <i></i>
            <span>增加一条</span>
        </div>
        <!-- 筛选 -->
      
    </div>
</template>

<script>
import Sortable from "sortablejs";
export default {
    name: "TableList",

    props: {
        ButtonWidth: {
            type: [String, Number],
            default: 180
        },
        fixed: {
            type: [String],
            default: "right"
        },
        btnFixed: {
            type: [Boolean],
            default: false
        },
        tableData: {
            type: [Array, Object],
            default: []
        },
        colrs: {
            type: [Array],
            default: []
        },
        total: {
            type: [String, Number],
            default: 0
        },
        filters: {},
        defaultfilter: {},
        pagesize: {
            type: [String, Number],
            default: 15
        },
        pageIndex: {
            type: [String, Number],
            default: 1
        },
        isShowAddLine: {
            type: [Boolean],
            default: false
        },
        showSummary: {
            type: [Boolean],
            default: false
        },
        loading: {
            type: [Boolean],
            default: false
        },
        selection: {
            type: [Boolean],
            default: false
        },
        height: {
            type: [String, Number]
        },
        hideOnSinglePage: {
            type: [Boolean],
            default: false
        },
        stripe: {
            type: [Boolean],
            default: true
        },
        filterItemkey:{
            type:Number,
            default: 0
        }
    },
    data() {
        return {
            tableKey: 0,
            tableHeight: 50,
            isShowInput: false,
            filterModuleDialog: false,
            // 筛选 Start
            TableFilters: this.filters,
            TableDefaultFilter: this.defaultfilter,
            filterProp: "",
            filterModuleFilters: {},
            filterModuleDefaultFilter: [],
            // 筛选End
            dom: "",
            filterkey:0,

            hoverRowIndex: null
        };
    },
    mounted() {
    
        // this.rowDrop()
    },

    methods: {
      
        // 表头样式
        headerCellStyle({ row, column, rowIndex, columnIndex }) {
           
            return "background: #F1F5F9;";
           
            
        },
        handleStyle(label) {
  
            
            return "background: #F1F5F9;";
        },

        rowClassName({ row, column, rowIndex, columnIndex }) {
            
            return rowIndex === this.tableData.length - 1 && row.id=='汇总' ? 'last-row' : '';
        },
       

        filterModuleDialogVisible(row){
            this.filterModuleDialog=row
        },

       

        showInput(isInput, index) {
            if (isInput) {
                this.isShowInput = true;
                this.colrs[index].isInput = true;
                // // console.log(this.colrs);
            }
        },
        headerIconClick() {},
        // 处理选中的行
        handleSelectionChange(val) {
            this.$emit("handleSelectionChange", val);
        },
        // 增加一行
        addLine() {
            this.$emit("handleAddLine", true);
            this.$nextTick(()=>{
                document.getElementsByClassName('cellContent').forEach((item,index) => {
                   if (item.parentNode.parentNode.className.indexOf('cellBox1')==-1) {
                        if (item.innerText=='' || (item.innerText.indexOf('%')!=-1 && item.innerText[0]=='%')) {
                            item.parentNode.className='cellBox cellBoxInputShow'
                        }
                   }
                })
            })
        },
        // 改变每页显示数量
        handleSizeChange(size) {
            this.$emit("handleSizeChange", size);
        },
        currentChange(val) {
            this.$emit("currentChange", val);
        },
        modifyItem(data) {
            // // console.log(data);
        },
        // 筛选
        filter(filterProp, filters, defaultfilter) {
            this.filterProp = filterProp;
            this.filterModuleFilters = filters;
            this.filterModuleDefaultFilter = defaultfilter;
            this.filterModuleDialog = true;
        },
        // 筛选回调
        changeFilter(selectedFilter) {
            this.$emit("changeFilter", {
                filterProp: this.filterProp,
                selectedFilter: selectedFilter
            });
        },
        //行拖拽
        rowDrop() {
            let dragTbale = document.getElementsByClassName("dragTbale");

            for (let i = 0; i < dragTbale.length; i++) {
                const tbody = document.querySelector("tbody");
                // const tbody=dragTbale[i].childNodes[0].childNodes[0].childNodes[2].childNodes[0].childNodes[1];

                const _this = this;
                Sortable.create(tbody, {
                    draggable: ".el-table__row",
                    onEnd({ newIndex, oldIndex }) {
                        const currRow = _this.tableData.splice(oldIndex, 1)[0];
                        _this.tableData.splice(newIndex, 0, currRow);
                    }
                });
            }
        },
    },
    activated() {
        //解决使用keep-alive后 element表格错位问题
        this.$nextTick(() => {
            this.$refs.table.doLayout();
        });
    },
    watch: {
        filters: {
            deep: true,
            handler(val) {
                this.TableFilters = val;
                this.tableKey++;
            }
        },
        defaultfilter: {
            deep: true,
            handler(val) {
                
                this.TableDefaultFilter = val;
                this.tableKey++;
            }
        },
        filterItemkey(n,o){
            this.filterkey=n
        },
    },
    computed: {
        currentPage: {
            get() {
                return this.pageIndex;
            },
            set() {}
        }
    }
};
</script>

<style lang="scss" scoped>
.TableList {
    .ManagementTable {
        margin-top: 12px;
        overflow: auto;
        height: 78vh;
    }
    .addLine {
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        margin: 12px 0 20px 20px;
        i {
            width: 12px;
            height: 12px;
            // background: url("../../assets/image/icon_add.png") no-repeat center / 12px;
            margin-right: 4px;
        }
        span {
            font-size: 12px;
            font-weight: bold;
            color: #457ff5;
            line-height: 16px;
        }
    }
}

/deep/ .last-row td{
    background: #f1f5f9 !important;
}


/deep/.el-table__body tr:hover>td{
    background-color: rgb(186, 200, 216) !important;
 }
 
/deep/ .el-table tr {
    position: relative;
}
/deep/ .updateBg {
    background: #E8E4FF;
}
/deep/ .addBg {
    background: #e6fff5;
}
/deep/ .del_line {
    color: #999;
    pointer-events:none;
    cursor:default
}
/deep/ .del_line .cellContent{
    color: #999;
}
/deep/ .del_line::after {
    content: no-open-quote;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    border-bottom: 1px solid #999;
}
/* 去掉中间数据的分割线 */
/deep/ .el-table__row > td {
    // border: none;
}
/* 去掉上面的线 */
/deep/ .el-table th.is-leaf {
    border: none;
}
/* 去掉最下面的那一条线 */
/deep/ .el-table::before {
    height: 0px;
}

/deep/.el-table__fixed,
/deep/.el-table__fixed-right {
    padding-bottom: 8px;
}
/deep/::-webkit-scrollbar {
    height: 10px;
}
</style>
